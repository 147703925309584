import CSS from "csstype";

const listStyles: CSS.Properties = {
  fontSize: "1rem",
  textDecoration: "none",
  listStyle: "none",
  margin: 0,
};

const List = ({
  items,
  px = 5,
  pb = 0,
  textAlign = "left",
  itemStyles = {},
}: {
  items: string[];
  px?: number;
  pb?: number;
  textAlign?: CSS.Property.TextAlign;
  itemStyles?: CSS.Properties;
}): JSX.Element => {
  return (
    <ul style={{ ...listStyles, textAlign }} className={` px-${px} pb-${pb}`}>
      {items.map((item: string) => (
        <li
          key={item}
          className={`font-posterama-light my-1`}
          style={itemStyles}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default List;
