import CSS from "csstype";

const headlineStyles: CSS.Properties = {
  color: "#44a1d1",
  fontSize: "4.5rem",
};

const Headline = ({ text }: { text: string }): JSX.Element => {
  return (
    <div
      style={headlineStyles}
      className={"font-bon-voyage-regular blue-headline my-2"}
    >
      {text}
    </div>
  );
};

export default Headline;
