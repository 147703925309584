import { Row } from "react-bootstrap";
import Headline from "../components/Headline";
import Treatment from "../components/Treatment";
import SegmentWrapper from "./SegmentWrapper";

const Face = (): JSX.Element => {
  return (
    <SegmentWrapper>
      <Row>
        <Headline text="Gesicht" />
      </Row>
      <Treatment
        title="GESICHTSBEHANDLUNG ANTI-AGE DELUXE"
        price={70}
        addOn={{ price: 5, title: "FÄRBEN" }}
        description={
          "Eine intensive Wirkstoffbehandlung für eine gestraffte und gepflegte Haut"
        }
        services={[
          "HAUTANALYSE",
          "PEELING",
          "REINIGUNG",
          "AUGENBRAUENKORREKTUR",
          "ROSENQUARZMASSAGE",
          "WIRKSTOFFMASKE",
        ]}
      />
      <Treatment
        title={"GESICHTSBEHANDLUNG CLASSIC"}
        price={50}
        addOn={{ price: 5, title: "FÄRBEN" }}
        description={
          "Behandlung für ein reines, frisches und ausgeglichenes Hautbild"
        }
        services={[
          "HAUTANALYSE",
          "PEELING",
          "REINIGUNG",
          "AUGENBRAUENKORREKTUR",
          "WIRKSTOFFMASSAGE",
          "MASKE",
        ]}
      />

      <Treatment
        title={"GESICHTSBEHANDLUNG Men"}
        price={50}
        description={"Behandlung für ein reines, frisches und klares Hautbild"}
        services={[
          "HAUTANALYSE",
          "PEELING",
          "REINIGUNG",
          "AUGENBRAUENKORREKTUR",
          "NASENHAARENTFERNUNG",
          "WIRKSTOFFMASSAGE",
          "MASKE",
          "ABSCHLUSSPFLEGE",
        ]}
      />
    </SegmentWrapper>
  );
};

export default Face;
