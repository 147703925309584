import CSS from "csstype";

const styles: CSS.Properties = {
  color: "black",
  whiteSpace: "pre-line",
  fontSize: "1.5rem",
};

const TextBox = ({
  text,
  textAlign = "left",
}: {
  text: string;
  textAlign?: CSS.Property.TextAlign;
}): JSX.Element => {
  return (
    <p
      style={{ ...styles, textAlign }}
      className={"font-bon-voyage-regular my-3 custom-text-box"}
    >
      {text}
    </p>
  );
};

export default TextBox;
