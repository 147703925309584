import { Col, Row } from "react-bootstrap";
import SegmentWrapper from "./SegmentWrapper";
import portrait from "../images/portrait.jpeg";
import CSS from "csstype";
import fbLogo from "../images/f_logo_RGB-Black_58.png";
import igLogo from "../images/ig_logo.png";
import Headline from "../components/Headline";
import List from "../components/List";
import { Link } from "react-router-dom";

const logoStyles: CSS.Properties = {
  height: "2.5rem",
  display: "inline-block",
};

const Contact = (): JSX.Element => {
  return (
    <SegmentWrapper isFullHeight>
      <Row>
        <Headline text="Kontakt" />
      </Row>
      <Row className="my-5">
        <Col
          className={"d-flex align-items-center justify-content-center col-12"}
        >
          <img
            style={{ height: "320px", borderRadius: "5px" }}
            src={portrait}
            alt="portrait"
          />
        </Col>
      </Row>
      <Row className={" align-items-center justify-content-center flex-column"}>
        <div className={"mt-3"}>
          <h3
            style={{ letterSpacing: "0.813rem" }}
            className={"font-posterama-light"}
          >
            KARIN FREITAG
          </h3>
          <h6
            style={{ letterSpacing: "0.188rem" }}
            className={"font-posterama-semi-bold"}
          >
            COSMETIC ART STUDIO
          </h6>
        </div>
        <List
          textAlign="center"
          items={["01987 SCHWARZHEIDE", "RUHLANDER STRASSE 20"]}
        />
        <div className={"mb-3"}></div>
        <List
          itemStyles={{ fontWeight: "bold" }}
          textAlign="center"
          items={["TERMIN NACH VEREINBARUNG", "TELEFON: 035752 50386"]}
        />

        <div className={"my-5"}>
          <a
            href="https://www.facebook.com/karin.freitag.cosmetic"
            target="_blank"
          >
            <img className={"mx-2"} style={logoStyles} src={fbLogo} />
          </a>
          <a
            href="https://www.instagram.com/cosmeticart.karinfreitag/"
            target="_blank"
          >
            <img className={"mx-2"} style={logoStyles} src={igLogo} />
          </a>
        </div>
        <div className="my-2">
          <Link
            style={{ textDecoration: "none", color: "white" }}
            className={"font-posterama-light"}
            to="/imprint"
            target="_blank"
          >
            Impressum
          </Link>
        </div>
      </Row>
    </SegmentWrapper>
  );
};

export default Contact;
