import { Row } from "react-bootstrap";
import Headline from "../components/Headline";
import Treatment from "../components/Treatment";
import SegmentWrapper from "./SegmentWrapper";

const PediManicure = (): JSX.Element => {
  return (
    <SegmentWrapper>
      <Row>
        <Headline text="Nagelpflege" />
      </Row>
      <Treatment
        title="PEDIKÜRE"
        price={25}
        addOn={{ price: 5, title: "Nagellack" }}
        description={"Schöne und gepflegte Füße, die sich sehen lassen können"}
        services={[
          "FUSSBAD",
          "NÄGEL KÜRZEN",
          "HORNHAUT ENTFERNEN",
          "PLEGE UND MASSAGE",
        ]}
      />
      <Treatment
        title="Maniküre"
        price={60}
        addOn={{ price: 5, title: "Nagellack" }}
        description={
          "Gepflegte Hände und Nägel sind ein Aushängeschild. \n Gönnen Sie ihren Händen ein erholsames Upgrade."
        }
        services={["PEELING", "HANDBAD", "NÄGEL FEILEN", "PFLEGE UND MASSAGE"]}
      />
    </SegmentWrapper>
  );
};

export default PediManicure;
