import { useState, useEffect, RefObject } from "react";

export default function useFadeIn(
  ref: RefObject<HTMLElement>,
  withTranslateY = false,
): string {
  const initialClasses = ["appear"];
  if (withTranslateY) {
    initialClasses.push("appear-translate-y");
  }
  const [cssClasses, setCssClasses] = useState(initialClasses);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            setCssClasses(["appear", "in-view"]);
            observer.unobserve(ref.current!);
          }
        });
      },
      { threshold: 0.5 },
    );
    observer.observe(ref.current!);
  }, [ref]);

  return cssClasses.join(" ");
}
