import { Row } from "react-bootstrap";
import Headline from "../components/Headline";
import Treatment from "../components/Treatment";
import SegmentWrapper from "./SegmentWrapper";

const Massages = (): JSX.Element => {
  return (
    <SegmentWrapper>
      <Row>
        <Headline text="Massagen" />
      </Row>
      <Treatment
        title="Rückenmassage"
        price={25}
        description={
          "Entfliehen Sie dem Alltag und gönnen Sie sich einen Moment der Ruhe bei einer Rückenmassage mit warmen, ätherischen Ölen"
        }
      />
    </SegmentWrapper>
  );
};

export default Massages;
