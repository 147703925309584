import { Row } from "react-bootstrap";
import Headline from "../components/Headline";
import TextBox from "../components/TextBox";
import Treatment from "../components/Treatment";
import SegmentWrapper from "./SegmentWrapper";

const MakeUp = (): JSX.Element => {
  return (
    <SegmentWrapper>
      <Row>
        <Headline text="Make Up" />
        <div className="px-5 col-12">
          <TextBox
            text={"Unterstreichen Sie Ihre individuelle Schönheit"}
            textAlign="center"
          />
        </div>
      </Row>
    </SegmentWrapper>
  );
};

export default MakeUp;
