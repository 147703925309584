import CSS from "csstype";
import { Row } from "react-bootstrap";

const styles: CSS.Properties = {
  color: "black",
  fontSize: "1.3rem",
  textAlign: "left",
  textTransform: "uppercase",
  letterSpacing: "0.125rem",
};

const TreatmentTitleWithoutPrice = ({
  text,
}: {
  text: string;
}): JSX.Element => {
  return (
    <Row className=" align-items-center">
      <h2
        style={styles}
        className={"font-posterama-semi-bold col-xs-12 m-0 treatment-title"}
      >
        <span className="py-3 px-5 d-inline-block bg-white treatment-title-bg-span">
          {text}
        </span>
      </h2>
    </Row>
  );
};

export default TreatmentTitleWithoutPrice;
