import { Row } from "react-bootstrap";
import Headline from "../components/Headline";
import Treatment from "../components/Treatment";
import SegmentWrapper from "./SegmentWrapper";

const Sugaring = (): JSX.Element => {
  return (
    <SegmentWrapper>
      <Row>
        <Headline text="Sugaring" />
      </Row>
      <Treatment
        title="Haarentfernung"
        price="10 - 25"
        description={
          "Sanfte Haarentfernung auf Zuckerbasis.\nIdeal für die Depilation empfindlicher Körperregionen"
        }
      />
    </SegmentWrapper>
  );
};

export default Sugaring;
