import Container from "react-bootstrap/Container";
import useFadeIn from "../hooks/fadeIn";
import { useRef } from "react";

const SegmentWrapper = ({
  children,
  isFullHeight = false,
  willAnimate = false,
  pt = 5,
}: {
  children: React.ReactNode;
  isFullHeight?: boolean;
  willAnimate?: boolean;
  pt?: number;
}): JSX.Element => {
  const segmentRef = useRef<HTMLDivElement>(null);
  const cssClassesSegment = useFadeIn(segmentRef, true);
  const styles = isFullHeight ? { minHeight: "100vh" } : {};

  return (
    <Container
      ref={segmentRef}
      fluid
      className={`background-light segment-wrapper pt-${pt} ${
        willAnimate && cssClassesSegment
      } ${
        isFullHeight
          ? "d-flex flex-column justify-content-evenly px-4"
          : "pb-lg-5"
      } pb-1`}
      style={styles}
    >
      {children}
    </Container>
  );
};

export default SegmentWrapper;
