import CSS from "csstype";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextBox from "../components/TextBox";
import useFadeIn from "../hooks/fadeIn";
import { useRef } from "react";
import SegmentWrapper from "./SegmentWrapper";

const headerStyles: CSS.Properties = {
  letterSpacing: "0.813rem",
  fontSize: "4.5rem",
};

const subheaderStyles: CSS.Properties = {
  letterSpacing: "0.188rem",
  fontSize: "1.5rem",
};

const Hero = (): JSX.Element => {
  const titleRef = useRef(null);
  const cssClassesTitle = useFadeIn(titleRef);
  const citeRef = useRef(null);
  const cssClassesCite = useFadeIn(citeRef, true);

  return (
    <SegmentWrapper isFullHeight pt={0}>
      <Row
        ref={titleRef}
        className={`hero-title-wrapper ${cssClassesTitle}`}
        style={{ margin: "10% auto" }}
      >
        <Col
          as={"h1"}
          className={`font-posterama-light col-12 hero-title`}
          style={headerStyles}
        >
          KARIN FREITAG
        </Col>
        <Col
          as={"h6"}
          className={"font-posterama-semi-bold col-12 hero-sub-title"}
          style={subheaderStyles}
        >
          COSMETIC ART STUDIO
        </Col>
      </Row>
      <Row ref={citeRef} className={cssClassesCite}>
        <Col className="col-12">
          <TextBox
            textAlign="center"
            text={
              "Wenn wir uns auf die Stille einlassen,\n werden wir die Welt mit anderen Augen anschauen. \n Wir werden erkennen, worum es\n in unserem Leben eigentlich geht."
            }
          />
        </Col>
        <Col className={"text-white font-posterama-semi-bold col-12"}>
          ANSELM GRÜN
        </Col>
      </Row>
    </SegmentWrapper>
  );
};

export default Hero;
