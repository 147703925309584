import { Row, Col } from "react-bootstrap";
import TextBox from "../components/TextBox";
import SegmentWrapper from "./SegmentWrapper";
import List from "../components/List";
import useFadeIn from "../hooks/fadeIn";
import { useRef } from "react";

const Welcome = (): JSX.Element => {
  const highlightsRef = useRef<HTMLDivElement>(null);
  const cssClassesHighlights = useFadeIn(highlightsRef, true);
  const productsRef = useRef<HTMLDivElement>(null);
  const cssClassesProducts = useFadeIn(productsRef);
  const textRef = useRef<HTMLDivElement>(null);
  const cssClassesText = useFadeIn(textRef);

  const highlights = [
    "Kosmetische Wirksamkeit",
    "Wohlfühlambiente",
    "Behandlungserlebnis",
  ];
  return (
    <SegmentWrapper pt={0}>
      <Row
        ref={highlightsRef}
        className={`justify-content-evenly align-items-center flex-column mb-5 ${cssClassesHighlights}`}
      >
        {highlights.map((highlight: string, index: number) => (
          <Col key={highlight} className="">
            {index > 0 && (
              <div
                key={`vertical-line-${index}`}
                style={{
                  height: "3rem",
                  color: "white",
                  background: "white",
                  width: "2px",
                  margin: "1rem auto",
                }}
              ></div>
            )}
            <h4
              key={`highlight-${index}`}
              className="w-100 m-0 py-3 px-3  font-posterama-semi-bold "
            >
              {highlight}
            </h4>
          </Col>
        ))}
      </Row>
      <Row ref={textRef} className={`justify-content-center ${cssClassesText}`}>
        <Col className="col-lg-8 col-md-12">
          <TextBox
            textAlign="center"
            text={
              "Nehmen Sie sich eine Auszeit vom Alltag im Cosmetic Art Studio. Sie erhalten professionelle Pflege Ihrer Haut, Ruhe und Momente der Entspannung. Eine individuelle Beratung, bei der Sie und Ihre Ansprüche im Mittelpunkt stehen, prägt mein Konzept."
            }
          />
        </Col>
      </Row>
      <Row
        ref={productsRef}
        className={`justify-content-center ${cssClassesProducts}`}
      >
        <Col className="col-12">
          <TextBox
            textAlign="center"
            text={"Ich behandle exklusiv mit Produkten dieser Marken:"}
          />
        </Col>
        <Col className="col-12">
          <List
            textAlign="center"
            pb={5}
            items={[
              "DR. GRANDEL COSMETIC",
              "PHYRIS PREMIUM SPA COSMETIC",
              "ARABESQUE MAKE UP",
              "GEHWOL FUSSPFLEGE",
            ]}
          ></List>
        </Col>
      </Row>
    </SegmentWrapper>
  );
};

export default Welcome;
