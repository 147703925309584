import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Contact from "./segments/Contact";
import Face from "./segments/Face";
import Hero from "./segments/Hero";
import MakeUp from "./segments/MakeUp";
import Massages from "./segments/Massages";
import PediManicure from "./segments/PediManicure";
import Sugaring from "./segments/Sugaring";
import Welcome from "./segments/Welcome";

function App(): JSX.Element {
  return (
    <div className="App">
      <Hero />
      <Welcome />
      <div className="w-100 bg-white p-4 segment-gap"></div>
      <Face />
      <div className="w-100 bg-white p-4 segment-gap"></div>
      <MakeUp />
      <div className="w-100 bg-white p-4 segment-gap"></div>
      <PediManicure />
      <div className="w-100 bg-white p-4 segment-gap"></div>
      <Sugaring />
      <div className="w-100 bg-white p-4 segment-gap"></div>
      <Massages />
      <div className="w-100 bg-white p-4 segment-gap"></div>
      <Contact />
    </div>
  );
}

export default App;
