import { useRef } from "react";
import { Row } from "react-bootstrap";
import useFadeIn from "../hooks/fadeIn";
import List from "./List";
import TextBox from "./TextBox";
import TreatmentTitleWithoutPrice from "./TreatmentTitleWithoutPrice";

export interface AddOn {
  price: number;
  title: string;
}

interface TreatmentProps {
  title: string;
  addOn?: AddOn;
  price: number | string;
  description: string;
  services?: string[];
}

const Treatment = ({
  title,
  price,
  addOn,
  description,
  services,
}: TreatmentProps): JSX.Element => {
  const treatmentRef = useRef<HTMLDivElement>(null);
  const cssClassesSegment = useFadeIn(treatmentRef, true);

  return (
    <Row>
      <div
        ref={treatmentRef}
        className={`treatment px-0 py-5 ${cssClassesSegment}`}
      >
        <TreatmentTitleWithoutPrice text={title} />
        <div className="px-5 col-lg-6 col-12">
          <TextBox text={description} />
        </div>
        {services && <List items={services} textAlign="right" />}
      </div>
    </Row>
  );
};

export default Treatment;
